import { contract } from '@forgd/contract'
import { initClient, tsRestFetchApi } from '@ts-rest/core'

export function useClient() {
  const runtimeConfig = useRuntimeConfig()
  const teammateAccessWall = useTeammateAccessWall()
  const baseUrl = process.server ? runtimeConfig.public.apiUrl : '/proxy/'

  const cookie = useCookie<string>(runtimeConfig.public.cookie)
  const headers: Record<string, string> = process.server
    ? {
        cookie: `${runtimeConfig.public.cookie}=${cookie.value}`,
      }
    : {}

  return initClient(contract, {
    baseUrl,
    baseHeaders: headers,
    credentials: 'include',
    api: async (args) => {
      const response = await tsRestFetchApi(args)

      if (response.status === 403) {
        teammateAccessWall.show()
      }

      return response
    },
  })
}
