import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import { GetActivitiesLeadResult, PostActivitiesLead } from '../types'
import { ProjectActivityStatusEnum } from '@forgd/supabase'

const c = initContract()

export const activitiesContract = c.router({
  setStatus: {
    method: 'POST',
    path: 'activities/status',
    responses: {
      204: z.undefined(),
    },
    body: z.object({
      projectId: z.string().uuid(),
      activityId: z.string(),
      status: z.nativeEnum(ProjectActivityStatusEnum),
    }),
    summary: 'Set the status of an activity',
  },
  postActivitiesLead: {
    method: 'POST',
    path: 'activities/lead',
    responses: {
      204: z.undefined().optional(),
    },
    body: PostActivitiesLead,
    summary: 'Create activities lead',
  },
  getActivitiesLead: {
    method: 'GET',
    path: 'activities/lead',
    query: z.object({
      projectId: z.string(),
    }),
    responses: {
      200: GetActivitiesLeadResult,
      204: z.undefined().optional(),
    },
    summary: 'Get activities lead',
  },
})

export type ActivitiesContractType = typeof activitiesContract
