import PrimeVue from 'primevue/config'
import { defineNuxtPlugin } from '#app'
import { defineAsyncComponent } from '#imports'

const ForgdDesignSystem = {
  wrapper: {
    class: 'overflow-visible',
  },
  table: {
    class: 'w-full table-auto border-collapse font-display text-sm',
  },
  column: {
    headercell: {
      class:
        'py-4 border-b border-forgd-300 text-gray-600 items-center font-light text-left text-xs',
    },
    bodycell: () => ({
      class: 'py-4 font-semibold whitespace-nowrap',
    }),
  },
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(PrimeVue, { unstyled: true, pt: ForgdDesignSystem })
  nuxtApp.vueApp.component('DataTable', defineAsyncComponent(() => import('primevue/datatable')))
})
