import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import {
  Dashboard,
  DistributionGroup,
  TokenDetailsBody,
  TokenDetailsResponse,
  UnlockProgressResponse,
  UpcomingTokenUnlocksResponse,
} from '../types'
import { ProjectPathParam } from '../types/common'

const c = initContract()

const numberStringSchema = z
  .string()
  .refine((value) => !isNaN(Number(value)), {
    message: 'Invalid number string',
  })
  .transform((value) => Number(value))

export const tokenUnlockContract = c.router({
  getTokenDetails: {
    method: 'GET',
    path: `token-unlock/:projectId/details`,
    responses: {
      200: TokenDetailsResponse,
    },
    pathParams: ProjectPathParam,
    summary: 'Get token details for a project',
  },
  updateTokenDetails: {
    method: 'PATCH',
    path: `token-unlock/:projectId`,
    responses: {
      200: TokenDetailsResponse,
    },
    pathParams: ProjectPathParam,
    body: TokenDetailsBody,
    summary: 'Update token details',
  },
  getUnlockProgress: {
    method: 'GET',
    path: `token-unlock/:projectId/progress`,
    responses: {
      200: UnlockProgressResponse,
    },
    pathParams: ProjectPathParam,
    summary: 'Get token unlock progress for a project',
  },
  getUpcomingTokenUnlocks: {
    method: 'GET',
    path: `token-unlock/:projectId/upcoming`,
    responses: {
      200: UpcomingTokenUnlocksResponse,
    },
    query: z.object({
      futureTimeFrame: numberStringSchema,
      circulatingTokenSupply: numberStringSchema,
    }),
    pathParams: ProjectPathParam,
    summary: 'Get upcoming token unlocks for a project',
  },
  getFullData: {
    method: 'GET',
    path: `token-unlock/:projectId/full`,
    responses: {
      200: z.object({
        groups: z.array(DistributionGroup),
        tokenDetails: TokenDetailsResponse,
        dashboard: Dashboard,
        unlockProgress: UnlockProgressResponse,
        upcomingTokenUnlocks: UpcomingTokenUnlocksResponse,
      }),
    },
    query: z.object({
      futureTimeFrame: numberStringSchema,
    }),
    pathParams: ProjectPathParam,
    summary: 'Get token unlocks full information for a project',
  },
})

export type TokenUnlockContractType = typeof tokenUnlockContract
