<script lang="ts">
import type { IconClass } from '#core/config/ui'
import type { PropType } from 'vue'
import { UButton } from '#components'
import { defineComponent, h } from 'vue'
import { defineUi, getContent, getHandlers, makeProps } from '~/utils/nuxt-ui'

const Base = UButton

const eventNames = ['click']

// define the ui
const ui = defineUi('button', {
  base: `     justify-center items-center flex-shrink-0 h-fit`,
  rounded: '  rounded-lg',
  padding: {
    xs: `     py-1 px-2`,
    sm: `     py-1.5 px-2`,
    md: `     py-[10px] px-3`,
    lg: `     py-[15px] px-5`,
    xl: `     py-[18px] px-10`,
  },
  size: {
    xs: `     text-xs`,
    sm: `     text-xs`,
    md: `     text-sm`,
    lg: `     text-sm`,
    xl: `     text-[16px]`, // md is not being picked up, strangely
  },
  icon: {
    size: {
      xs: `     size-[12px]`,
      sm: `     size-[16px]`,
      md: `     size-[20px]`,
      lg: `     size-[20px]`,
      xl: `     size-[24px]`,
    },
  },
  variant: {
    solid: `  bg-primary-900 text-neutral-100
              hover:bg-primary-500 hover:text-neutral-100
              disabled:bg-neutral-300 disabled:ring-neutral-600 disabled:text-neutral-600
            `,
    outline: `ring-neutral-600 text-primary-900
              hover:bg-neutral-600/30 hover:ring-neutral-900
              disabled:bg-neutral-600/30 disabled:ring-neutral-600 disabled:text-neutral-600
              `,

    ghost: `  bg-transparent text-primary-900
              hover:bg-neutral-600/30 hover:text-primary-900
              disabled:text-neutral-600 disabled:cursor-no-entry disabled:hover:bg-transparent
            `,
  },
})

// replacement for solid if variant is grouped
const grouped = ` ring-1 ring-neutral-600 bg-neutral-600/30 text-primary-900
                  group-hover:bg-primary-700 group-hover:ring-primary-700 group-hover:text-neutral-100
                  disabled:bg-neutral-600/30 disabled:ring-neutral-600 disabled:text-neutral-600
                  `

/**
 * UiButton
 *
 * Variant:
 *
 *  - defaults to outline
 *  - solid and ghost modifiers
 *
 * Icon:
 *
 *  - autocomplete for icon names
 *  - makes icon buttons square by default
 *  - tooltip for icon buttons
 *
 * Loading:
 *
 *  - automatically-chosen loading icon (for light and dark)
 *  - automatically places loading icon as trailing (unless already has an icon)
 *
 * Other:
 *
 *  - opens http links in new window
 *  - no unneeded props
 */
export default defineComponent({
  props: {
    solid: Boolean,
    ghost: Boolean,
    variant: {
      type: String as PropType<'solid' | 'outline' | 'ghost'>,
      default: 'outline',
    },
    size: {
      type: String as PropType<'xs' | 'sm' | 'md' | 'lg' | 'xl'>,
      default: 'md',
    },
    label: {
      type: String,
    },
    tooltip: { // used when icon only
      type: String,
    },
    icon: {
      type: String as PropType<IconClass>,
    },
    trailing: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    to: {
      type: String,
    },
    block: {
      type: Boolean,
      default: false,
    },
    grouped: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
  },

  emits: eventNames,

  setup(props, { attrs, slots, emit }) {
    return () => {
      // content
      const content = getContent(slots, props, 'label')

      // icon only
      const iconOnly = props.icon && !content.value

      // build props
      const newProps = makeProps<typeof Base>((output) => {
        // variant
        output.variant = props.solid || props.grouped
          ? 'solid'
          : props.ghost
            ? 'ghost'
            : props.variant

        // size
        output.size = props.size

        // label
        if (props.label) {
          output.label = props.label
        }

        // icon
        if (props.icon) {
          output.icon = props.icon
          if (iconOnly) {
            output.square = true
          }
        }

        // trailing
        output.trailing = !props.icon && props.loading
          ? true
          : props.trailing

        // loading
        if (props.loading) {
          output.loadingIcon = output.variant === 'solid'
            ? 'i-loading-icon-light'
            : 'i-loading-icon'
        }

        // link
        if (props.to) {
          // BUG: links not working in storybook
          output.to = props.to
          if (props.to?.startsWith('https:')) {
            output.target = '_blank'
          }
        }

        // rest
        output.type = props.type
        output.loading = props.loading
        output.block = props.block
        output.disabled = props.loading
          ? false
          : props.disabled

        // return
        return output
      })

      // tooltip for icon only
      const tooltip = iconOnly && !props.loading
        ? props.tooltip
        : undefined

      // classes
      const classes = {
        // make aria-label behave as a tooltip
        tooltip,

        // old way of showing loading icon
        // loader: props.loading,

        // new way of showing loading icon
        '!opacity-100 !text-neutral-100 !bg-primary-500': newProps.loading && newProps.variant === 'solid',
        '!opacity-100 !text-primary-900': newProps.loading && !!newProps.variant?.match(/outline|ghost/),
      }

      // replace solid variant if props.variant is grouped
      const newUi = props.grouped
        ? { ...ui, variant: { solid: grouped } }
        : ui

      // final options
      const options = {
        ...attrs,
        ...newProps,
        ...getHandlers(eventNames, emit),
        'data-ui': 'UiButton',
        'aria-label': tooltip,
        'class': classes,
        'ui': newUi,
      }

      // return
      return h(Base, options, slots.default)
    }
  },
})
</script>
