import unhead_S7VEKW5Nx3 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_3n4fmtbusqliidkzutv5523i74/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_hPVNiDiq1t from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_3n4fmtbusqliidkzutv5523i74/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_0Xg0t7ySbj from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+supabase@1.4.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_Afx4H4XHC7 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_3n4fmtbusqliidkzutv5523i74/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_HxpZkCOi6K from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_3n4fmtbusqliidkzutv5523i74/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_awhOu7G3O5 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_3n4fmtbusqliidkzutv5523i74/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_pYdZNj8s7H from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_3n4fmtbusqliidkzutv5523i74/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_rp9I9afTeV from "/opt/render/project/src/node_modules/.pnpm/@pinia+nuxt@0.5.3_magicast@0.3.5_rollup@4.21.3_typescript@5.6.2_vue@3.5.6_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/apps/ui/.nuxt/components.plugin.mjs";
import prefetch_client_8EIybES6N3 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_3n4fmtbusqliidkzutv5523i74/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import preview_client_2qDxQSCuVS from "/opt/render/project/src/node_modules/.pnpm/@nuxthq+studio@1.1.2_magicast@0.3.5_rollup@4.21.3_webpack-sources@3.2.3/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.mjs";
import slideovers_pbnR051wRl from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.21.3_t_cpsjeg4d2ppis2w2byoksuiqxi/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_mCeFKNLCjz from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.21.3_t_cpsjeg4d2ppis2w2byoksuiqxi/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_BmkJgOQ2yb from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.21.3_t_cpsjeg4d2ppis2w2byoksuiqxi/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_MD7XwdJiBL from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_magicast@0.3.5_rollup@4.21.3_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_zkNyEXm8Hr from "/opt/render/project/src/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.5_rollup@4.21.3_vite@5.4.4_@types+node@22.5.5_sass-embedded@1.7_rqunyajdt3uf6nyvgi263eddoy/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import feature_flags_client_yDiIZxtHJu from "/opt/render/project/src/apps/ui/core/plugins/feature-flags.client.ts";
import lottie_YCAtSIlAiw from "/opt/render/project/src/apps/ui/core/plugins/lottie.ts";
import primevue_MOhlnAFvlV from "/opt/render/project/src/apps/ui/core/plugins/primevue.js";
import sentry_client_4BqsDhRlQY from "/opt/render/project/src/apps/ui/core/plugins/sentry.client.ts";
export default [
  unhead_S7VEKW5Nx3,
  router_hPVNiDiq1t,
  supabase_client_0Xg0t7ySbj,
  payload_client_Afx4H4XHC7,
  navigation_repaint_client_HxpZkCOi6K,
  check_outdated_build_client_awhOu7G3O5,
  chunk_reload_client_pYdZNj8s7H,
  plugin_vue3_rp9I9afTeV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8EIybES6N3,
  preview_client_2qDxQSCuVS,
  slideovers_pbnR051wRl,
  modals_mCeFKNLCjz,
  colors_BmkJgOQ2yb,
  plugin_client_MD7XwdJiBL,
  plugin_zkNyEXm8Hr,
  feature_flags_client_yDiIZxtHJu,
  lottie_YCAtSIlAiw,
  primevue_MOhlnAFvlV,
  sentry_client_4BqsDhRlQY
]